@import 'src/assets/scss/general/mixins.scss';
@import 'src/assets/scss/framework/scss-variables.scss';

.mask-on-image {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;

    @include media('>tiny') {
      background-position: center center;
    }

    @include media('<=tiny') {
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 53.12%,
        #ffffff 84.9%
      );
      background-position: center bottom;
    }
  }

  &_1 {
    &::before {
      @include media('>tiny') {
        background-image: url('../../../assets/img/common/mask-1.png');
      }
    }
  }

  &_2 {
    &::before {
      @include media('>tiny') {
        background-image: url('../../../assets/img/common/mask-2.png');
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
