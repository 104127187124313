@import 'assets/scss/framework/scss-variables';
@import 'assets/scss/general/mixins';

.container {
  background-color: var(--white-theme-bg);
  padding-top: 24px;
  padding-bottom: 24px;

  max-height: calc(100vh - 56px - #{$mobileHeadereHeight});

  border-radius: 0 0 12px 12px;
  position: absolute;
  right: 0;
  left: 0;
  overflow: auto;
  overscroll-behavior: contain;
  z-index: 2;

  :global(.device-desktop) & {
    left: auto;
    width: 400px;
    z-index: 2;
    box-shadow: 0px 10px 19px rgba(120, 128, 148, 0.12);

    @include media('>1400px') {
      right: calc(50% - var(--wrap) / 2);
    }

    @include media('<=1400px') {
      right: 0;
    }
  }
}

.schedule {
  span {
    margin-right: 12px;
  }
}

.phone {
  &_item_li {
    padding-top: 20px;
  }

  &_number {
    display: flex;
    align-items: center;
    font-size: 20px;

    & > svg {
      margin-right: 10px;
    }
  }
}

.address {
  display: block;
  padding-top: 16px;
  padding-bottom: 20px;
  border-bottom: $borderBottom;
  font-style: normal;
  line-height: 1.5em;
}

.call_form {
  padding-top: 20px;

  margin-bottom: 20px;
}

.phone {
  padding-top: 12px;
  padding-bottom: 12px;
}

.submit {
  display: block;
  margin-right: auto;
  margin-left: auto;

  :global(.device-desktop) &,
  :global(.device-mobile) & {
    width: 100%;
  }
}

.close {
  position: absolute;
  top: -40px;
  right: -40px;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  border-radius: 50%;

  :global(.device-mobile) & {
    display: none;
  }
}
