.underlay {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.4s;
  }

  &-enter-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-done {
    opacity: 0;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.2s;
  }
}

.slide-down {
  &-enter {
    transform: translate3d(0, -105%, 0);
  }

  &-enter-active {
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s ease-in-out 0.1s;
  }

  &-enter-done {
    transform: translate3d(0, 0, 0);
  }

  &-exit {
    transform: translate3d(0, 0, 0);
  }

  &-exit-done {
    transform: translate3d(0, -100%, 0);
  }

  &-exit-active {
    transform: translate3d(0, -100%, 0);
    transition: transform 0.1s ease-in-out 0.1s;
  }
}

.slide-top {
  &-enter {
    transform: translate3d(0, 100%, 0);
  }

  &-enter-active {
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s ease-in-out 0.1s;
  }

  &-enter-done {
    transform: translate3d(0, 0, 0);
  }

  &-exit {
    transform: translate3d(0, 0, 0);
  }

  &-exit-done {
    transform: translate3d(0, 100%, 0);
  }

  &-exit-active {
    transform: translate3d(0, 100%, 0);
    transition: transform 0.1s ease-in-out 0.1s;
  }
}

.slide-right {
  &-enter {
    opacity: 0.3;
    transform: translate3d(-100%, 0, 0);
  }

  &-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s ease-in-out 0.1s, opacity 0.05s;
  }

  &-enter-done {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &-exit-done {
    opacity: 0.3;
    transform: translate3d(-100%, 0, 0);
  }

  &-exit-active {
    opacity: 0.3;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.1s ease-in-out 0.1s, opacity 0.1s 0.15;
  }
}

.slide-left {
  &-enter {
    transform: translate3d(100%, 0, 0);
  }

  &-enter-active {
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s ease-in-out 0.1s;
  }

  &-enter-done {
    transform: translate3d(0, 0, 0);
  }

  &-exit {
    transform: translate3d(0, 0, 0);
  }

  &-exit-done {
    transform: translate3d(100%, 0, 0);
  }

  &-exit-active {
    transform: translate3d(100%, 0, 0);
    transition: transform 0.1s ease-in-out 0.1s;
  }
}

.item-gift {
  &-enter {
    & [class*='gift_inner'] {
      opacity: 0;
      transform: scale(1, 0.5);
    }
  }

  &-enter-active {
    & [class*='gift_inner'] {
      opacity: 1;
      transform: scale(1, 1);
      transition: transform 0.2s ease-in-out, opacity 0.05s;
    }
  }

  &-enter-done {
    & [class*='gift_inner'] {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  &-exit {
    & [class*='gift_inner'] {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  &-exit-done {
    & [class*='gift_inner'] {
      opacity: 0;
      transform: scale(1, 0.5);
    }
  }

  &-exit-active {
    & [class*='gift_inner'] {
      opacity: 0;
      transform: scale(1, 0.5);
      transition: transform 0.2s ease-in-out, opacity 0.05s 0.15;
    }
  }
}

.fade {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.4s ease-in-out 0.1s;
  }

  &-enter-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-done {
    opacity: 0;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.05s ease-in-out;
  }
}

// fade-appear, fade-appear-active, fade-appear-done
// fade-enter, fade-enter-active, fade-enter-done
// fade-exit, fade-exit-active, fade-exit-done
