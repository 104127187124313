@import 'assets/scss/framework/scss-variables';
@import 'assets/scss/general/mixins';

.container {
  position: fixed;

  background: #ffffff;

  z-index: 5;

  :global(.device-mobile) & {
    bottom: 0;
    // top: 0;
    left: 0;
    right: 0;

    max-height: calc(100vh - 56px);
    overflow: auto;
    overscroll-behavior: contain;

    border-radius: 12px 12px 0 0;
  }

  :global(.device-tablet) &,
  :global(.device-desktop) & {
    width: 440px;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    border-radius: 12px;
  }
}

.form {
  padding-top: 30px;
  padding-bottom: 30px;
}

.top {
  padding-bottom: 12px;
  border-bottom: 10px solid var(--border-light);
}

.title {
  font-size: 18px;
  padding-bottom: 10px;
}

.bottom {
  padding-top: 20px;

  &_question {
    padding: 16px 20px;
    color: #ffffff;
    background: #72849b;
    border-radius: 16px 16px 16px 4px;
    transform-origin: left bottom;
    transform: scale(0, 0);

    :global(.slide-top-enter-done) &,
    :global(.fade-enter-done) & {
      transform: scale(1, 1);
      transition: transform 0.2s ease-in-out 0.05s;
    }

    span {
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);

      :global(.slide-top-enter-done) &,
      :global(.fade-enter-done) & {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        transition: clip-path 0.3s ease-in-out 0.15s;
      }
    }

    &_inner {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: row-reverse;
      padding-bottom: 24px;
    }
  }
}

.submit {
  width: 100%;
}

.img {
  padding-top: 10px;
  margin-right: 16px;
}

.textarea {
  margin-bottom: 28px;
  width: 100%;
  padding: 20px;
  border: 1px solid #e5eaed;
  font-size: 18px;
  line-height: 1.5em;
  border-radius: 16px 16px 4px 16px;

  @include placeholder {
    color: var(--text-light);
    font: inherit;
    filter: opacity(0.8);
  }
}

.close {
  position: absolute;
  top: -44px;
  right: -44px;
  height: 48px;
  width: 48px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(39, 43, 48, 0.1);

  :global(.device-mobile) & {
    display: none;
  }
}
