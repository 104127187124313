@import 'assets/scss/framework/scss-variables';
@import 'assets/scss/general/mixins';

.footer {
  padding-top: 78px;
  padding-bottom: 60px;
  border-top: 1px solid #e5eaed;
  display: flex;
  margin: 0 -8px;
  width: auto;

  &.dark {
    border-top: none;

    .col,
    .col :global(.ba-h3) a,
    .services a,
    :global(.ba-footer__contacts-phones) a,
    .policy_link,
    :global(.ba-footer__contacts-address),
    :global(.ba-socials-list a) {
      color: var(--dark-theme-text);
    }

    :global(.ba-footer__message-btn .ba-btn--outline) {
      color: var(--dark-theme-text);
      border-color: var(--dark-theme-text);
    }
  }

  :global(.ba-footer__message) {
    padding-top: 0;
  }

  :global(.ba-footer__contacts-phones),
  :global(.ba-footer__contacts-schedule) {
    flex: none;
  }

  :global(.ba-footer__contacts-schedule) {
    margin-top: 28px;
  }

  :global(.ba-footer__bottom) {
    margin-top: auto;
    padding-bottom: 0;
  }
}

.col {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  flex: 1;

  :global(.ba-h3) {
    margin-bottom: 25px;
  }
}

.wide {
  flex: 1.5;
}

.middle {
  flex: 1.25;
}

.small {
  flex: 0.75;
}

.eShop {
  svg {
    margin-left: -30px;
    margin-right: 8px;
  }

  a {
    color: #2364ad !important;
    font-size: 24px;
  }
}

.services {
  display: flex;
  flex-wrap: wrap;

  & > li {
    font-size: 14px;
    flex: 0 0 50%;
    margin-bottom: 24px;
    max-width: 200px;

    & > a {
      transition: color 0.2s linear;

      &:hover {
        color: var(--primary-pressed);
      }
    }
  }
}

.policy {
  margin-top: auto;
  padding-top: 16px;

  &_link {
    color: #555a61;
    font-size: 11px;
    line-height: 1.45em;
    transition: color 0.2s;

    &:hover {
      color: var(--primary-pressed);
    }
  }
}
