@import './assets/scss/general/mixins';
@import './assets/scss/framework/scss-variables';
@import './assets/scss/framework/css-variables';

@import './assets/scss/reset';

@import './assets/scss/typography';
@import './assets/scss/colors';

@import './assets/scss/components/tag';
@import './assets/scss/components/mask-on-image.scss';

:root {
  --visibleDuration: 0.3s;
  --unVisibleDuration: 0.4s;
  --unVisibleDelay: 0.3s;
}

// * {
//   outline: 1px solid red;
// }

html {
  scroll-behavior: smooth;
}

body:not(.mac-os) {
  @include media('>900px') {
    *,
    & {
      &::-webkit-scrollbar {
        border-radius: 4px;
        width: 9px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.01);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #72849b;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.animation-show-feed {
  &-enter {
    opacity: 0;
    transform: translateY(-20px);
  }

  &-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s;
  }

  &-exit {
  }

  &-exit-active {
  }
}

body {
  min-width: $extratiny;
  position: relative;
  // -webkit-overflow-scrolling: touch;
  // overscroll-behavior: contain;
  scroll-behavior: smooth;

  &.no-scroll {
    overflow: hidden;

    &.iOS {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

.ba-delimiter {
  border-bottom: $borderBottom;
}

.ba-wrap {
  max-width: var(--wrap);
  padding: 0 var(--gap);
  margin: 0 auto;
}

// .ba-animate-visible {
//   &:not(.ba-el-visible) {
//     opacity: 0;
//     transition: opacity var(--unVisibleDuration) ease var(--unVisibleDelay);
//   }

//   transition: opacity var(--visibleDuration) ease-in-out;
// }

.ba-anim-delimiter {
  height: 25vh;
  position: relative;
  // z-index: -1;
  pointer-events: none;
  margin-top: -12vh;
  margin-bottom: -12vh;
  outline: 1px solid red;
}

.ba-underlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 4;

  .device-tablet & {
    z-index: 3;
  }

  .device-desktop & {
    z-index: 4;
  }
}

.ba-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .ba-footer {
    margin-top: auto;
  }
}

.ba-icon-plus {
  display: inline-block;
  height: 24px;
  width: 24px;
  padding: 5px;

  b {
    position: relative;
    display: block;
    height: 100%;

    &:after,
    &:before {
      position: absolute;
      content: '';
      background-color: currentColor;
      border-radius: 1px;
    }

    &:after {
      display: block;
      top: 0;
      left: calc(50% - 1px);
      height: 100%;
      width: 2px;
      transition: transform 0.15s;
    }

    &:before {
      top: calc(50% - 1px);
      left: 0;
      height: 2px;
      width: 100%;
    }
  }
}
