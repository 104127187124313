@import '../../../assets/scss/framework/scss-variables';
@import '../../../assets/scss/general/mixins';

.ba-switcher {
  display: inline-flex;
  padding: 2px;
  border: 1px solid #efeef2;
  border-radius: 12px;

  label {
    display: block;
    padding: 2px;
    flex: 1;

    input {
      // position: absolute;
      // height: 0;
      // left: 0;
      // z-index: -1;
      // opacity: 0;

      display: none;
    }

    span {
      user-select: none;
      display: block;
      padding: 0 32px;
      font-size: 16px;
      height: 42px;
      line-height: 42px;
      border-radius: 8px;
      text-align: center;

      transition: background 0.2s, color 0.2s;

      &:hover {
        background: #f4ecf3;
      }
    }

    input:checked + span {
      color: #ffffff;
      background: var(--violet);
    }
  }
}
