@import './scss-variables';

:root {
  --wrap: 1440px;
  --wrap-text: 840px;

  --white: #fff;

  --primary: #58b7a4;
  --primary-hover: #6dccba;
  --primary-pressed: #52ae9d;
  --primary-bg: #84b0a4;

  --text: #243346;
  --text-mid: #3e4957;
  --text-mid-2: #495667;
  --text-light: #72849b;
  --border-light: #e5eaed;
  --red: #ff5c59;
  --blue: #2364ad;
  --blue-2: #4d6583;
  --grey: #a9b7c8;
  --muddy-purple: #8a8fab;

  --shop-grey: #525c68;

  --bg-white: #ffffff;

  --bg-light: #f2f4f5;
  --bg-dark: #46576a;
  --btn-small: #8997a7;
  --btn-dark-red: #b67e7e;
  --btn-dark-red-hover: #cf9999;
  --gradient-bg: linear-gradient(#8ba6cf, #8ba6cf);
  --violet: #b59fb1;

  --white-theme-bg: #fff;
  --white-theme-text: var(--text);
  --dark-theme-bg: #141e30;
  --dark-theme-text: #fff;

  --cub-transition: cubic-bezier(0.65, 0, 0.35, 1);

  @include media('>tiny') {
    --gap: 30px;
  }

  @include media('<=tiny') {
    --gap: 16px;
  }

  @include media('>tiny') {
    --heigthHeader: #{$desktopHeadereHeight};
  }

  @include media('<=tiny') {
    --heigthHeader: #{$mobileHeadereHeight};
  }
}
