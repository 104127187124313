@import 'assets/scss/framework/scss-variables';
@import 'assets/scss/general/mixins';

.container {
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate3d(-50%, -50px, 0);

  width: 280px;

  background: #ffffff;
  border-radius: 12px;

  padding: 30px 20px;

  z-index: 4;
}

.icon {
  color: var(--primary);
  margin-bottom: 4px;
}

.title {
  margin-bottom: 4px;
  line-height: 1.5em;
}

.text {
  line-height: 1.5em;
}
