// Media queries breakpoints
$extratiny: 320px;
$tiny: 576px;
$small: 768px;
$medium: 992px;
$large: 1200px;
$extralarge: 1600px;

// Sizes
$wrap: 1440px;
$mobileHeadereHeight: 69px;
$desktopHeadereHeight: 80px;

$borderBottom: 1px solid var(--border-light);

// settings of "include-media"
$breakpoints: (
  extratiny: $extratiny,
  tiny: $tiny,
  small: $small,
  medium: $medium,
  large: $large,
  extralarge: $extralarge,
);
