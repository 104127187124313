.ba-tag {
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  font-size: 14px;
  background: #58b7a4;
  border-radius: 8px 0px;
  padding: 6px 8px;
}
