@import 'assets/scss/framework/scss-variables';
@import 'assets/scss/general/mixins';

.container {
  position: relative;

  & > img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }

  &:after {
    display: block;
    position: absolute;
    bottom: 6px;
    right: -2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #46cab1;
    border: 2px solid #ffffff;
    content: '';

    animation: onlinePulse 2s ease-in-out infinite;
  }
}

@keyframes onlinePulse {
  0% {
    transform: scale(0.84);
  }
  5% {
    transform: scale(0.84);
  }
  45% {
    transform: scale(1);
  }
  55% {
    transform: scale(1);
  }
  95% {
    transform: scale(0.84);
  }
  100% {
    transform: scale(0.84);
  }
}
