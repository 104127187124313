@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('/fonts/Gilroy-Bold.woff') format('woff'),
    url('/fonts/Gilroy-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url('/fonts/Gilroy-Regular.woff2') format('woff2'),
    url('/fonts/Gilroy-Regular.woff') format('woff'),
    url('/fonts/Gilroy-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
    url('/fonts/Gilroy-SemiBold.woff2') format('woff2'),
    url('/fonts/Gilroy-SemiBold.woff') format('woff'),
    url('/fonts/Gilroy-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
    url('/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('/fonts/Gilroy-Medium.woff') format('woff'),
    url('/fonts/Gilroy-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RC';
  src: url('/fonts/ComforterBrush-Regular.woff2') format('woff2'),
    url('/fonts/ComforterBrush-Regular.woff') format('woff'),
    url('/fonts/ComforterBrush-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}