.ba-primary {
  color: var(--primary) !important;
}

.ba-primary-hover {
  color: var(--primary-hover);
}

.ba-base {
  color: var(--text) !important;
}

.ba-text-light {
  color: var(--text-light) !important;
}

.ba-white {
  color: #ffffff !important;
}

.ba-grey {
  color: var(--grey);
}

.ba-mid-2 {
  color: var(--text-mid-2);
}

.ba-violet {
  color: var(--violet);
}
