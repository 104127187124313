@import 'assets/scss/framework/scss-variables';
@import 'assets/scss/general/mixins';

.container {
  position: fixed;
  background: #ffffff;

  padding-top: 30px;
  padding-bottom: 30px;

  z-index: 5;

  form {
    margin-bottom: 30px;
  }

  :global(.device-mobile) & {
    bottom: 0;
    left: 0;
    right: 0;

    max-height: calc(100vh - 56px);
    overflow: auto;
    overscroll-behavior: contain;

    border-radius: 12px 12px 0 0;
  }

  :global(.device-tablet) &,
  :global(.device-desktop) & {
    width: 440px;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    border-radius: 12px;
  }
}

.input {
  &_wrap {
    margin-bottom: 20px;
  }
}

.switcher {
  width: 100%;

  &_wrap {
    margin-bottom: 26px;
  }
}

.note {
  margin-top: 20px;
  margin-bottom: 24px;
  color: #495667;
}

.submit {
  width: 100%;

  :global(.device-mobile) & {
    margin-bottom: 30px;
  }
}

.close {
  position: absolute;
  top: -44px;
  right: -44px;
  height: 48px;
  width: 48px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(39, 43, 48, 0.1);

  :global(.mobile-state) & {
    display: none;
  }
}
