*,
*:after,
*:before {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
  border: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  // text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-decoration-skip: objects;
  line-height: 1;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: 100%;
  color: #000;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus {
  outline: none;
}

main {
  display: block;
}

/* Remove list styles on ul, ol elements with a class attribute */
ol,
ul {
  list-style: none;
}

ol {
  counter-reset: myCounter;
}

ol li:before {
  font: inherit;
  color: inherit;
  counter-increment: myCounter;
  content: counter(myCounter) '.';
  padding-right: 1em;
}

/* A elements that don't have a class get default styles */
a {
  text-decoration-skip-ink: auto;
  text-decoration: none;
}

b {
  font-weight: bolder;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
}

td {
  font-weight: normal;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  border: 0;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  background: transparent;
  font: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  border: none;
  outline: none;
}

textarea {
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-ms-clear {
  display: none;
}

figure {
  -webkit-margin-after: 0;
  -webkit-margin-before: 0;
}

[type='reset'],
[type='submit'] {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

input::-webkit-date-and-time-value {
  margin: 0;
}

input[type='radio'] {
  vertical-align: text-bottom;
}

input[type='checkbox'] {
  vertical-align: bottom;
}

label,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
  -webkit-appearance: none;
}

/*remove dotted outline/border in Firefox*/
a,
button::-moz-focus-inner {
  border: 0;
}

button:focus,
a:focus,
a:active,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: none !important;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: none;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

[type='search'] {
  outline: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-ms-clear {
  display: none;
}

::selection {
  color: #fff;
  background-color: #004fe4;
}

:-webkit-autofill {
  box-shadow: 0 0 100px #fff inset;
  -webkit-text-fill-color: currentColor;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

/*edge input reset*/
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

pre {
  white-space: pre-line;
  font-family: monospace, monospace;
}

[hidden] {
  display: none;
}

/* Remove all animations and transitions for people that prefer not to see them */
// @media (prefers-reduced-motion: reduce) {
//   * {
//     animation-duration: 0.01ms !important;
//     animation-iteration-count: 1 !important;
//     transition-duration: 0.01ms !important;
//     scroll-behavior: auto !important;
//   }
// }
