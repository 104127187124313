@import './fonts';

body,
button,
input,
textarea,
a {
  font-family: 'Gilroy', sans-serif;
  color: var(--text);
}

h1,
.ba-h1 {
  font-weight: bold;

  @include media('>tiny') {
    font-size: 42px;
  }

  @include media('<=tiny') {
    font-size: 28px;
  }
}

h2,
.ba-h2 {
  font-weight: bold;

  @include media('>tiny') {
    font-size: 36px;
  }

  @include media('<=tiny') {
    font-size: 24px;
  }
}

h3,
.ba-h3 {
  font-weight: bold;

  @include media('>tiny') {
    font-size: 24px;
  }

  @include media('<=tiny') {
    font-size: 20px;
  }
}

h4,
.ba-h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.4em;
}

h5,
.ba-h5 {
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 600;
}

h6,
.ba-h6 {
  line-height: 1.2em;
  font-family: 'RC', sans-serif;
  font-weight: normal;

  @include media('>medium') {
    font-size: 72px;
  }

  @include media('<=medium') {
    font-size: 56px;
  }
}

.ba-lead {
  @include media('>tiny') {
    font-size: 22px;
  }

  @include media('<=tiny') {
    font-size: 20px;
  }
}

p,
.ba-reg {
  line-height: 1.5em;

  @include media('>tiny') {
    font-size: 18px;
  }

  @include media('<=tiny') {
    font-size: 16px;
  }
}

small,
.ba-small {
  font-size: 14px;
}

.ba-tags {
  font-weight: bold;
  font-size: 14px;
}

.ba-btn {
  font-weight: bold;

  @include media('>tiny') {
    font-size: 20px;
  }

  @include media('<=tiny') {
    font-size: 18px;
  }
}

.ba-h1.ba-RC {
  font-size: 56px;
}

.ba-RC {
  font-family: 'RC', sans-serif;
}

.ba-RC-h2 {
  font-weight: normal;
  @include fluid-type($medium, $large, 56px, 72px);
}

.ba-center {
  text-align: center;
}

.ba-bold {
  font-weight: bold;
}

.ba-semibild {
  font-weight: 600;
}
